
import { useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { IconButton } from "@mui/material";
import HeaderGestorConfigs from '../../../../utils/headerGestorConfigs';

function DocumentViewer({ pdfUrls, certificado }) {
  // console.log(pdfUrls)
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0);
  const arrayPdfUrls = Object.entries(pdfUrls).map(([name, url]) => ({ name, url }));
  const handleSelectPdf = (index) => {
    setSelectedPdfIndex(index);
  };

  return (
    <div>
       <HeaderGestorConfigs title={'DOCUMENTOS ANEXADOS'} />

      <select
        value={selectedPdfIndex}
        onChange={(e) => handleSelectPdf(parseInt(e.target.value, 10))}
        style={{
          padding: '8px',
          fontSize: '16px',
          borderRadius: '4px',
          maxWidth: '80%'
          // margin: '10px',
        }}
      >
        {arrayPdfUrls.map((pdf, index) => (
          <option key={index} value={index}>
            {pdf.name === "solicitacao" && 'Solicitação'}
            {pdf.name === "autorizacaoExportacao" && 'Autorização de Exportação'}
            {pdf.name === "parecerDNSP" && 'Parecer da Direcção Nacional de Saúde Pública para a importação de antituberculosos'}
            {pdf.name === "parecerINLS" && 'Parecer do Instituto Nacional de Luta contra a Sida para a importação de anti-retrovirais'}
            {pdf.name === "copiaValidacao" && 'Cópia da validação emitida pelo Instituto Nacional de Investigação em Saúde, para a importação de Testes de Diagnóstico Rápido para rastreio e diagnóstico de doenças infecciosas'}
            {pdf.name === "cartaOriginal" && 'Carta original da unidade hospitalar dirigida ao importador, assinada e carimbada pelo Director Geral da unidade hospitalar, para a importação de estupefacientes'}
            {pdf.name === "faturaPro" && 'Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola)'}
            {pdf.name === "faturaEmolumento" && 'Factura de pagamento de emolumento Armed'}
            {pdf.name === "certificadoBPF" && 'Certificado de Boas Práticas de Fabrico (GMP)'}
            {pdf.name === "copiaFI" && 'Cópia do Folheto Informativo, rótulo que acompanha o medicamento, deve incluir a Língua portuguesa'}
            
            {/* Desalfandegamento */}

            {pdf.name === "solicitacao" && 'Carta	Solicitação dirigida à ARMED'}
            {/* {pdf.name === "autorizacaoExportacao" && ''} */}
            {pdf.name === "certificadoCQL" && 'Certificado de controlo de qualidade por lotes'}
            {pdf.name === "certificadoOP" && 'Certificado de Origem dos produtos'}
            {pdf.name === "copiaCIEA" && 'Copia do CIEA'}
            {/* {pdf.name === "faturaEmolumento" && ''} */}
            {pdf.name === "folhetoILP" && 'Folheto informativo'}
            {/* {pdf.name === "parecerDNSP" && ''} */}
            {pdf.name === "receitaMedica" && 'Receita Médica'}
          </option>
        ))}
      </select>

      <IconButton size='large' disabled={selectedPdfIndex < 1} type="submiy" onClick={() => setSelectedPdfIndex(selectedPdfIndex - 1)}>
        <NavigateBeforeIcon />
      </IconButton>

      <IconButton size='large' disabled={selectedPdfIndex === arrayPdfUrls.length - 1} type="submiy" onClick={() => setSelectedPdfIndex(selectedPdfIndex + 1)}>
        <NavigateNextIcon />
      </IconButton>

      <iframe
        id="pdfIframe"
        title={`PDF Viewer`}
        src={arrayPdfUrls[selectedPdfIndex].url}
        width="100%"
        height="600px"
        style={{ border: 'none' }}
      />
    </div>
  );
}

export default DocumentViewer;


