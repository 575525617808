import { Card, IconButton } from "@mui/material";
import HeaderGestorConfigs from "../../../../../utils/headerGestorConfigs";
import VisibilityIcon from "@mui/icons-material/DescriptionOutlined";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Date from "../../../../../utils/date";
// import { CertificadoPdf } from "./docsEmitidos/certificadopdf";


export default function DocumentosEmitidos({ solicitacaoImportacao }) {
    return (
        <Card style={{ padding: 15, borderRadius: 5 }}>
            <HeaderGestorConfigs title={'DOCUMENTOS EMITIDOS'} />
            {solicitacaoImportacao?.certificado &&
                <p>
                    <IconButton color="secondary"
                    // onClick={() => CertificadoPdf()}
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton color="secondary"
                    // onClick={() => {comprovativopdf(historico)}
                    >
                        <DownloadOutlinedIcon />
                    </IconButton>

                    CERTIFICADO PARA IMPORTAÇÃO_{solicitacaoImportacao?.certificado?.numeroCertificado} _ <Date date={solicitacaoImportacao?.certificado.dataEmissao} />
                </p>
            }

            {solicitacaoImportacao?.declaracao &&
                <p>
                    <IconButton color="secondary"
                    // onClick={() => CertificadoPdf()}
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton color="secondary"
                    // onClick={() => {comprovativopdf(historico)}
                    >
                        <DownloadOutlinedIcon />
                    </IconButton>

                    DECLARAÇÃO DESALFANDEGAMENTO_{solicitacaoImportacao?.declaracao?.numeroCertificado} _ <Date date={solicitacaoImportacao?.declaracao.dataEmissao} />
                </p>
            }


            {/* <iframe
        id="pdfIframe"
        title={`PDF Viewer`}
        // src={viewRelatorio}
        width="100%"
        height="500px"
        style={{ border: 'none' }}
    /> */}
        </Card>
    )
}
