
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import { makeStyles } from '@material-ui/core';
import { Avatar, Box, Card, Grid, IconButton, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import api from '../services/api';
import Loading from '../load/loading';
import MessageError from '../messages/messageError';
import ImagemEdit from '../utils/imagemEdit';
import CloseIcon from '@mui/icons-material/Close';
// import StatusEstabelecimento from '../utils/statusEstabelecimento';


const useStales = makeStyles({
    icone: {
        margin: 7,
        color: '#fff'
    },
    hover: { '&:hover': { backgroundColor: '#d2d6ff', cursor: 'pointer', } }
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

export default function BuscaEstabelecimento(props) {
    const classes = useStales()
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [pesquisa, setPesquisa] = useState('');
    const [openLoad, setOpenLoad] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [select, setSelect] = useState('')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const searchEstabelecimento = async (e) => {
        e.preventDefault()
        setPesquisa('')
        setMessageError('')
        setOpenLoad(true)

        try {
            const response = await api.post(`/estabelecimento/search?perPage=10`, { search })
            setPesquisa(response.data.estabelecimentos);
            setOpenLoad(false)

        } catch (error) {
            if (error.message === "Network Error") {
                setMessageError(error.message)
            }
            setOpenLoad(false)
            setPesquisa('');
            setMessageError(error.response.data.message);
        }
    }

    return (
        <div>
            <Tooltip title="Pesquisar Estabelecimento">
                <Box sx={{ display: { xs: 'none', md: 'inline' } }}>
                    <span noWrap style={{ color: '#fff', fontSize: 13 }}>PESQUISAR ESTABELECIMENTO</span>
                </Box>
                <IconButton onClick={handleClickOpen} >
                    <img src="/icones/lupacnp.svg" width={25} alt="search" />
                </IconButton>

            </Tooltip>

            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogContent >
                    {messageError && <MessageError message={messageError} />}

                    {select && <IconButton color='error' title="fechar" style={{ position: 'absolute', right: 5, fontWeight: 'bold' }} onClick={() => setSelect('')}> <CloseIcon /></IconButton>}

                    <Box m={2}>
                        <form onSubmit={searchEstabelecimento} style={{ display: 'flex', }}>
                            <InputBase
                                style={{ maxWidth: 560, marginLeft: 'auto', marginRight: 'auto' }}
                                required
                                autoFocus
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Nome do estabelecimento"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <IconButton type="submiy" aria-label="search">
                                <ContentPasteSearchOutlinedIcon />
                            </IconButton>
                        </form>
                    </Box>

                    {openLoad && <Loading text={'A pesquisar estabelecimento'} />}


                    {(pesquisa && !select) &&
                        <Card variant="outlined">
                            <TableContainer >
                                <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow>
                                            <TableCell ><Typography noWrap> <strong>Imagem</strong></Typography></TableCell>
                                            <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                            {/* <TableCell ><Typography noWrap> <strong>Número de Processo</strong></Typography></TableCell> */}
                                            <TableCell ><Typography noWrap> <strong>Director Técnico</strong></Typography></TableCell>
                                            {/* <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell> */}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {pesquisa.map((pesquisa) => (
                                            <TableRow key={pesquisa?._id} className={classes.hover} onClick={() => setSelect(pesquisa)} title={pesquisa?.nome}>
                                                <TableCell component="th" scope="row">
                                                    <Avatar variant='square' src={pesquisa?.logo} alt={pesquisa?.nome} />
                                                </TableCell>
                                                <TableCell  >{pesquisa?.nome}</TableCell>
                                                {/* <TableCell  >{pesquisa?.numeroProcesso}</TableCell> */}
                                                <TableCell  >{pesquisa?.directorTecnico?.dadosPessoais?.nome}</TableCell>
                                                {/* <TableCell  ><StatusEstabelecimento status={pesquisa?.status}/></TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    }


                    {select &&
                        <Grid container>
                            <Grid item marginRight={2}>
                                <ImagemEdit
                                    data={select}
                                    url={'/estabelecimento/change-logo'}
                                    local={'estabelecimentoId'}
                                />
                            </Grid>

                            <Grid xs item>
                                {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Estado:</strong> {select?.status ?? '####'}</Typography> */}
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nome:</strong> {select?.nome ?? '####'}</Typography>
                                {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>E-mail:</strong> {select?.email ?? '####'} </Typography> */}
                                {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Estabelecimento:</strong> {select?.tipo?.designacao ?? '####'}</Typography> */}
                                {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Tipo de entrada:</strong> {select?.tipoEntrada ?? '####'}</Typography> */}
                                {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Número de processo:</strong> {select?.numeroProcesso ?? select?.numeroEntrada}</Typography> */}
                                {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Número de entrada:</strong> {select?.numeroEntrada ?? '####'}</Typography> */}
                                {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Data de entrada:</strong> {select?.dataEntrada ?? <DateHora date={select?.createdAt} />}</Typography> */}
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Rua / Avenida:</strong> {select?.rua ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Bairro:</strong> {select?.bairro ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Município:</strong> {select?.municipio ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Provincia:</strong> {select?.provincia ?? '####'} </Typography>
                            </Grid>
                        </Grid>
                    }

                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
